import React from 'react';
import {Datagrid, DateField, Filter, List, NumberField, ReferenceField, TextField, TextInput } from 'react-admin';
/**
 * Sub-component for filtering a list of settlementError.
 * @param props the react admin Filter component properties
 * @return {JSX.Element}
 * @constructor
 */
function SettlementErrorFilter (props) {
    return (
        <Filter {...props}>
            <TextInput source="date" alwaysOn/>
            <TextInput source="subId" alwaysOn/>
        </Filter>
    );
}

/**
 * Component for a list of settlementError.
 * @param props the react admin List component properties
 * @return {JSX.Element}
 * @constructor
 */
export default function SettlementErrorList({...props}) {
    // build the UI elements
    return (
        <List bulkActionButtons={false} filters={<SettlementErrorFilter/>} sort={{field: 'id', order: 'DESC'}} {...props}>
            <Datagrid rowClick="show">
                <DateField source="createdAt"/>
                <TextField source="date"/>
                <TextField source="subId"/>
                <TextField label="SEQ" source="seq"/>
                <NumberField source="amount" options={{style: 'currency', currency: 'KRW'}}/>
                <ReferenceField source="orderId" reference="orders">
                    <TextField source="id"/>
                </ReferenceField>
                <TextField source="status"/>
                <TextField source="error"/>
            </Datagrid>
        </List>
    );
}
