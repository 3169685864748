// in src/users.js
import React from 'react';
import {
    ArrayField,
    List,
    TopToolbar,
    Datagrid,
    ExportButton,
    TextField,
    Edit,
    SimpleForm,
    SelectInput,
    TextInput,
    Filter,
    NumberInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    ReferenceField,
    DateField,
    BooleanInput,
    DateInput,
    Toolbar,
    SaveButton, AutocompleteInput, SelectField, useRecordContext, FunctionField, ReferenceInput,
} from 'react-admin';
import withStyles from "@material-ui/core/styles/withStyles";
import rowStyle from './rowStyle'
import FormattableTextField from '../components/FormattableTextField';
import { textEllipsis } from '../utils/util';
import {AuditViewer} from '../audits/AuditList';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import CustomPriceField from '../components/CustomPriceField';
import {CustomBooleanField} from '../users';
import {payoutStatus} from '../tossPayouts/TossConstants';
import UserLinkField from '../components/UserLinkField';

const styles = {
    date: {
        whiteSpace: 'nowrap'
    },
    inline: {
        display: 'inline',
        marginRight: 20
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    table: {
        width: 'auto',
        tableLayout: 'fixed',
        marginBottom: 8
    },
    tableHead: {
        fontWeight: 'bold',
        backgroundColor: '#f5f5f5',
        width: '100px',
        whiteSpace: 'nowrap',
        border: '1px solid #e0e0e0',
        textAlign: 'center'
    },
    tableCell: {
        textAlign: 'center',
        border: '1px solid #e0e0e0'
    },
    mediumTableCell: {
        textAlign: 'center',
        border: '1px solid #e0e0e0',
        width: '150px'
    },
    largeTableCell: {
        textAlign: 'center',
        border: '1px solid #e0e0e0',
        width: '300px'
    }
}

const COURIER_COMPANIES = [
    {name: 'CJ대한통운'},
    {name: '우체국택배'},
    {name: '롯데택배'},
    {name: 'GS Postbox 택배'},
    {name: 'CU 편의점택배'},
    {name: '로젠택배'},
    {name: '홈픽택배'},
    {name: '경동택배'},
    {name: '대신택배'},
    {name: '일양로지스'},
    {name: '농협택배'},
    {name: '한진택배'},
    {name: '천일택배'},
    {name: '건영택배'},
    {name: '합동택배'},
    {name: 'SLX택배'},
    {name: '용마로지스'},
    {name: '로지스밸리택배'},
    {name: '큐런택배'},
    {name: '기타'},
];
const includeDeletedProductChoices = [
    { value: true, name: '삭제포함' },
    { value: false, name: '정상' },
]

const feeAssignedChoices = [
    { value: 'SELLER', name: '판매자' },
    { value: 'BUYER', name: '구매자' },
]

const cancelledBy = [
    { value: 'seller', name: '판매자' },
    { value: 'buyer', name: '구매자' },
    { value: 'admin', name: '어드민' },
]
const platformChoices = [
    { value: null, name: '전체' },
    { value: 'MOBILE', name: '모바일' },
    { value: 'WEB', name: '웹' },
]

const OrderFilter = (props) => {
    const statusChoices = [
        { value: 'all', name: '전체' },
        { value: 'pending', name: '결제 완료' },
        { value: 'shipping', name: '배송중' },
        { value: 'completed', name: '구매 확정' },
        { value: 'settled', name: '정산 완료' },
        { value: 'cancelled', name: '결제 취소' },
    ];

    const negotiationChoices = [
        { value: '', name: '전체' },
        { value: 'true', name: '네고 O' },
        { value: 'false', name: '네고 X' },
    ]

    return (
        <Filter {...props}>
            <SelectInput source="status" allowEmpty={false} choices={statusChoices} optionValue="value" alwaysOn label={'상태'}/>
            <SelectInput source="platform" allowEmpty={false} choices={platformChoices} optionValue="value" alwaysOn label={'플랫폼'}/>
            <SelectInput source="includeDeletedProduct" allowEmpty={false} label='삭제 여부' choices={includeDeletedProductChoices} filterDefaultValue={true} optionValue="value" alwaysOn/>
            <DateInput source="createdAt_from" label="paid From" alwaysOn />
            <DateInput source="createdAt_until" label="paid Until" alwaysOn />
            <TextInput source="id" label="거래번호"  alwaysOn/>
            <TextInput source="purchase_id" label="주문번호"  alwaysOn />
            <TextInput source={'product_title'} label={'상품 제목'} alwaysOn/>
            <NumberInput label="판매자 아이디" source="seller_id" alwaysOn/>
            <ReferenceInput source="seller_id" reference="users" label="판매자 유저네임" alwaysOn>
                <AutocompleteInput optionText="username"/>
            </ReferenceInput>
            <NumberInput label="구매자 아이디" source="user_id" alwaysOn/>
            <ReferenceInput source="user_id" reference="users" label="구매자 유저네임" alwaysOn>
                <AutocompleteInput optionText="username"/>
            </ReferenceInput>
            <TextInput label="구매자 휴대폰번호" source="customer_phone" alwaysOn/>
            <TextInput label="구매자 이름" source="customer_name" alwaysOn/>
            <TextInput label="운송장 번호" source="invoice_number" alwaysOn/>
            <SelectInput source="negotiation_id" allowEmpty={true} label={'네고 여부'} choices={negotiationChoices} optionValue="value" alwaysOn/>
            <SelectInput source="fee_assigned" label={'수수료 부담 대상'} allowEmpty={true} choices={feeAssignedChoices} optionValue="value" alwaysOn/>
        </Filter>
    );
}

const Settlement = () => {
    const record = useRecordContext();

    if (record.settlement) {
        return (
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>정산 ID</TableCell>
                        <TableCell style={styles.tableHead}>정산 예약일</TableCell>
                        <TableCell style={styles.tableHead}>정산 금액</TableCell>
                        <TableCell style={styles.tableHead}>상태</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <ReferenceField label="Settlement" source="settlement.id" reference="settlements" link="show"
                                            allowEmpty={true}>
                                <TextField source="id"/>
                            </ReferenceField>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField source={'settlement.date'}/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source={'settlement.amount'}/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField source={'settlement.status'}/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    if (record.toss_payouts) {
        return (
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>정산 ID</TableCell>
                        <TableCell style={styles.tableHead}>정산일</TableCell>
                        <TableCell style={styles.tableHead}>정산 금액</TableCell>
                        <TableCell style={styles.tableHead}>상태</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record.toss_payouts.map((payout) => (
                        <TableRow key={payout.id}>
                            <TableCell  style={styles.tableCell}>
                                <ReferenceField reference={'toss-payouts'} source={'id'} record={payout} link={'edit'}>
                                    <TextField source="id" />
                                </ReferenceField>
                            </TableCell>
                            <TableCell  style={styles.tableCell}>
                                <DateField record={payout} source="created_at" />
                            </TableCell>
                            <TableCell  style={styles.tableCell}>
                                <FunctionField
                                    source={'amount'}
                                    render={() => {
                                        const value = payout.amount;

                                        // 가격을 원 단위로 포맷팅
                                        return value != null
                                            ? `${new Intl.NumberFormat('ko-KR', {maximumFractionDigits: 0}).format(value)}원`
                                            : '0원';
                                    }}
                                />
                            </TableCell>
                            <TableCell  style={styles.tableCell}>
                                <SelectField
                                    choices={payoutStatus}
                                    record={payout}
                                    source={'status'}
                                    label={'상태'}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }

    return <></>
}

export const OrderShow = withStyles(styles)(({classes = {}, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <h3>기본정보</h3>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>Order ID</TableCell>
                        <TableCell style={styles.tableHead}>주문번호</TableCell>
                        <TableCell style={styles.tableHead}>구매자</TableCell>
                        <TableCell style={styles.tableHead}>판매자</TableCell>
                        <TableCell style={styles.tableHead}>상품 이름</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <TextField source="id"/>
                        </TableCell>
                        <TableCell style={styles.mediumTableCell}>
                            <TextField source="purchase_id"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <ReferenceField label="User" source="user_id" reference="users" link="show">
                                <TextField source={'username'}/>
                            </ReferenceField>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <ReferenceField label="Seller" source="seller_id" reference="users" link="show">
                                <TextField source={'username'}/>
                            </ReferenceField>
                        </TableCell>
                        <TableCell style={styles.largeTableCell}>
                            <ReferenceField
                                reference="products"
                                source="product_id"
                                label="product"
                                link="edit">
                                <TextField source="title"/>
                            </ReferenceField>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField label="price" source="price"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <h3>결제 정보</h3>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>상태</TableCell>
                        <TableCell style={styles.tableHead}>결제일</TableCell>
                        <TableCell style={styles.tableHead}>결제 플랫폼</TableCell>
                        <TableCell style={styles.tableHead}>운송장 등록일</TableCell>
                        <TableCell style={styles.tableHead}>구매확정일</TableCell>
                        <TableCell style={styles.tableHead}>취소일</TableCell>
                        <TableCell style={styles.tableHead}>취소 요청자</TableCell>
                        <TableCell style={styles.tableHead}>취소 사유</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <TextField label="status" source="status"/>
                        </TableCell>
                        <TableCell style={styles.mediumTableCell}>
                            <DateField label="paid Date" source="createdAt"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField source="platform"/>
                        </TableCell>
                        <TableCell style={styles.mediumTableCell}>
                            <DateField label="delivered Date" source="deliveredAt"/>
                        </TableCell>
                        <TableCell style={styles.mediumTableCell}>
                            <DateField label="completed Date" source="completedAt"/>
                        </TableCell>
                        <TableCell style={styles.mediumTableCell}>
                            <DateField label="cancelled At" source="cancelledAt"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <SelectField source="cancelled_by" choices={cancelledBy} optionValue="value"/>
                        </TableCell>
                        <TableCell style={styles.largeTableCell}>
                            <TextField label="cancellation reason" source="cancellation_reason"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <h3>배송 정보</h3>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>구매자 이름</TableCell>
                        <TableCell style={styles.tableHead}>핸드폰 번호</TableCell>
                        <TableCell style={styles.tableHead}>주소</TableCell>
                        <TableCell style={styles.tableHead}>도서/산간</TableCell>
                        <TableCell style={styles.tableHead}>우편번호</TableCell>
                        <TableCell style={styles.tableHead}>택배사</TableCell>
                        <TableCell style={styles.tableHead}>송장번호</TableCell>
                        <TableCell style={styles.tableHead}>요청사항</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <TextField label="customer name" source="customer_name"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField label="customer phone" source="customer_phone"/>
                        </TableCell>
                        <TableCell style={styles.largeTableCell}>
                            <TextField label="address" source="address"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomBooleanField label="is island" source="is_island"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField label="postal_code" source="postal_code"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField label="courier service" source="courier_service"/>
                        </TableCell>
                        <TableCell style={styles.mediumTableCell}>
                            <TextField label="invoice number" source="invoice_number"/>
                        </TableCell>
                        <TableCell style={styles.largeTableCell}>
                            <TextField label="message" source="message"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <h3>가격 정보</h3>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>수수료 부담 대상</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격</TableCell>
                        <TableCell style={styles.tableHead}>네고된 가격</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격과 네고 가격 차이 금액</TableCell>
                        <TableCell style={styles.tableHead}>쿠폰 할인 금액</TableCell>
                        <TableCell style={styles.tableHead}>배송비</TableCell>
                        <TableCell style={styles.tableHead}>수수료</TableCell>
                        <TableCell style={styles.tableHead}>최종 결제 금액</TableCell>
                        <TableCell style={styles.tableHead}>예상 정산 금액</TableCell>
                        <TableCell style={styles.tableHead}>카드 결제 ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <SelectField source="orderPayment.fee_assigned" choices={feeAssignedChoices}
                                         optionValue="value" defaultValue={'판매자'}/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="product.price"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="negotiation_price"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="difference_price"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="coupon_discount"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="shipping_fee"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.card_fee_amount"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="price"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.settlement_amount"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField source="orderPayment.merchant_uid"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {/* Settlement */}
            <h3>정산 정보</h3>
            <Settlement/>
            <div style={{width: '80vw'}}>
                <h3>정산 보류를 위한 안내사항</h3>
                <p>※ 정산 보류를 위해서는 <b>정산 예약일</b> 오전 10시 이전에 Order에 <b>상태(completed를 제외한 상태로)</b>를 변경해야 합니다.</p>
                <p> - 예시) <b>정산 예약일이 20250226</b>일 경우 2025년 2월 26일 오전 10시 이전에 상태를 변경하셔야 정산 보류가 가능합니다!</p>
                <p>※ 상태를 변경하면 기존 예약 되었던 정산이 취소되고, 추후 다시 정산을 신청하고자 하시면 상태를 <b>completed</b>로 변경하시면 됩니다.</p>
                <p>※ <b>completed</b>로 변경할 경우, 기존에 작성되어있는 Review는 Review 탭에서 지우고 실행하셔야 합니다.</p>
            </div>

            {/* Audit History */}
            <ArrayField source="audits">
                <Datagrid rowClick="expand" expand={<AuditViewer/>}>
                    <TextField source="id" label="Audit id"/>
                    <TextField source="auditableId" label="Order id"/>
                    <ReferenceField source="user_id" reference="users">
                        <UserLinkField/>
                    </ReferenceField>
                    <FormattableTextField source="data" format={(historyObject) => textEllipsis(JSON.stringify(historyObject), 120)}/>
                    <DateField source="createdAt" showTime className={classes.date}/>
                </Datagrid>
            </ArrayField>
            <ShowButton/>
        </SimpleShowLayout>
    </Show>
));
const CustomToolbar = withStyles(styles)(props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.pristine}/>
    </Toolbar>
));

export const OrderEdit = withStyles(styles)(({classes = {}, ...props}) => (
    <Edit {...props} undoable={false} redirect="list">
        <SimpleForm toolbar={<CustomToolbar/>}>
            <TextInput source="id" disabled/>
            <ReferenceField label="구매자" source="user_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField label="상품 제목" source="product_id" reference="products" link="edit">
                <TextField label="title" source="title"/>
            </ReferenceField>

            <div style={{width: '80vw'}}>
                <h3>상태변경시 주의사항</h3>
                <p>※ 상태를 <b>Pending에서 Cancelled</b>로 변경시, 결제가 취소되고 환불됩니다. 주의 부탁드립니다!</p>
                <p>※ 상태를 <b>Completed</b>로 변경시, 리뷰가 자동으로 생성됩니다. 주의 부탁드립니다!</p>
            </div>
            <SelectInput label="상태" source="status" choices={[
                {
                    id: 'pending', name: 'pending'
                }, {
                    id: 'shipping', name: 'shipping'
                }, {
                    id: 'completed', name: 'completed'
                }, {
                    id: 'settled', name: 'settled'
                }, {
                    id: 'cancelled', name: 'cancelled'
                }
            ]}/>

            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>결제 플랫폼</TableCell>
                        <TableCell style={styles.tableHead}>결제일</TableCell>
                        <TableCell style={styles.tableHead}>배송정보 등록일</TableCell>
                        <TableCell style={styles.tableHead}>구매확정일</TableCell>
                        <TableCell style={styles.tableHead}>취소 요청자</TableCell>
                        <TableCell style={styles.tableHead}>취소일</TableCell>
                        <TableCell style={styles.tableHead}>취소 사유</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <SelectField source='platform' choices={platformChoices} optionValue="value"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <DateField label="paid Date" source="createdAt"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <DateField label="delivered Date" source="deliveredAt"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <DateField label="completed Date" source="completedAt"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField label="cancelled by" source="cancelled_by"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <DateField label="cancelled At" source="cancelledAt"/>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField label="cancellation reason" source="cancellation_reason"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <TextInput label="구매자 이름" source="customer_name"/>
            <TextInput label="구매자 번호" source="customer_phone"/>
            <TextInput label="주소" source="address"/>
            <BooleanInput label="도서산간 여부" source="is_island"/>
            <TextInput label="우편번호" source="postal_code"/>
            <AutocompleteInput label="택배사" source="courier_service" choices={COURIER_COMPANIES}
                               optionValue="name" allowEmpty={true}/>
            <TextInput label="운송장 번호" source="invoice_number"/>
            <TextInput label="요청사항" source="message"/>

            <h3>가격 정보</h3>
            <Table style={styles.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={styles.tableHead}>수수료 부담 대상</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격</TableCell>
                        <TableCell style={styles.tableHead}>네고된 가격</TableCell>
                        <TableCell style={styles.tableHead}>상품 가격과 네고 가격 차이 금액</TableCell>
                        <TableCell style={styles.tableHead}>쿠폰 할인 금액</TableCell>
                        <TableCell style={styles.tableHead}>배송비</TableCell>
                        <TableCell style={styles.tableHead}>수수료</TableCell>
                        <TableCell style={styles.tableHead}>최종 결제 금액</TableCell>
                        <TableCell style={styles.tableHead}>예상 정산 금액</TableCell>
                        <TableCell style={styles.tableHead}>카드 결제 ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={styles.tableCell}>
                            <SelectField source="orderPayment.fee_assigned" choices={feeAssignedChoices} optionValue="value" defaultValue={'판매자'} />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="product.price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="negotiation_price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="difference_price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="coupon_discount" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="shipping_fee" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.card_fee_amount" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="price" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <CustomPriceField source="orderPayment.settlement_amount" />
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            <TextField source="orderPayment.merchant_uid"/>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {/* Settlement */}
            <h3>정산 정보</h3>
            <Settlement />
            <div style={{width: '80vw'}}>
                <h3>정산 보류를 위한 안내사항</h3>
                <p>※ 정산 보류를 위해서는 <b>정산 예약일</b> 오전 10시 이전에 Order에 <b>상태(completed를 제외한 상태로)</b>를 변경해야 합니다.</p>
                <p>  - 예시) <b>정산 예약일이 20250226</b>일 경우 2025년 2월 26일 오전 10시 이전에 상태를 변경하셔야 정산 보류가 가능합니다!</p>
                <p>※ 상태를 변경하면 기존 예약 되었던 정산이 취소되고, 추후 다시 정산을 신청하고자 하시면 상태를 <b>completed</b>로 변경하시면 됩니다.</p>
                <p>※ <b>completed</b>로 변경할 경우, 기존에 작성되어있는 Review는 Review 탭에서 지우고 실행하셔야 합니다.</p>
            </div>

            {/* Audit History */}
            <ArrayField source="audits">
            <Datagrid rowClick="expand" expand={<AuditViewer/>}>
                    <TextField source="id" label="Audit id"/>
                    <TextField source="auditableId" label="Order id"/>
                    <ReferenceField source="user_id" reference="users">
                        <UserLinkField/>
                    </ReferenceField>
                    <FormattableTextField source="data"
                                          format={(historyObject) => textEllipsis(JSON.stringify(historyObject), 120)}/>
                    <DateField source="createdAt" showTime className={classes.date}/>
                </Datagrid>
            </ArrayField>
        </SimpleForm>
    </Edit>
));

const OrderListActions = ({
                              bulkActionButtons,
                              basePath,
                              currentSort,
                              displayedFilters,
                              exporter,
                              filters,
                              filterValues,
                              onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActionButtons && React.cloneElement(bulkActionButtons, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
            maxResults={1000000}
        />
    </TopToolbar>
);

export const OrderList = props => (
    <List {...props} filters={<OrderFilter/>} actions={<OrderListActions />} sort={{field: 'id', order: 'DESC'}}>
        <Datagrid rowClick="edit" rowStyle={rowStyle}>
            <TextField source="id" label={'거래번호'}/>
            <ReferenceField label="판매자" source="seller_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField label="구매자" source="user_id" reference="users" link="show">
                <UserLinkField/>
            </ReferenceField>
            <ReferenceField label="상품 제목" source="product_id" reference="products" link="edit">
                <TextField label="title" source="title"/>
            </ReferenceField>
            <CustomPriceField label={'결제 금액'} source={'price'}/>
            <CustomPriceField label={'할인'} source={'coupon_discount'}/>
            <CustomPriceField label={'배송비'} source={'shipping_fee'}/>
            <TextField label="주문 번호" source="purchase_id"/>
            <TextField label="구매자 이름" source="customer_name"/>
            <TextField label="상태" source="status"/>
            <DateField label="결제일" source="createdAt"/>
            <DateField label="배송일" source="deliveredAt"/>
            <DateField label="구매 확정" source="completedAt"/>
            <ReferenceField label="정산일" source="settlement.id" reference="settlements" link="show" allowEmpty={true}>
                <DateField source="createdAt"/>
            </ReferenceField>
            <DateField label="취소일" source="cancelledAt"/>
            <TextField label="취소 요청자" source="cancelled_by"/>
            <TextField label="취소 사유" source="cancellation_reason"/>
        </Datagrid>
    </List>
);

